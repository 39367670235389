import React, { useState } from "react";
import axios from "axios";
import { server } from "../env";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/Loader";

const Login = () => {
  const [loader, setLoader] = useState("");

  const login = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      secretCode: e.target.secretCode.value,
    };

    await axios
      .post(server + "/v3/pairingapp/auth/login", data)
      .then((res) => {
        setLoader("");
        toast.success("Login Successful");
        Cookies.set("pairingApp", res.data.payload.token);
        window.location.href = "/";
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
        console.log(err);
      });
  };

  return (
    <div
      className="vh-100 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "#D6EFED" }}
    >
      <img
        src="/assets/wilyer.png"
        alt=""
        className="img-fluid mb-3"
        style={{
          width: "10%",
        }}
      />
      <div
        className="card lift shadow-sm"
        style={{
          width: "60%",
        }}
      >
        <div className="card-body justify-content-center align-items-center h-100">
          <form onSubmit={login}>
            <div className="form-group mb-3">
              <label className="fw-bold fs-6">Secret Code</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter secret code"
                name="secretCode"
              />
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-primary" type="submit">
                Login {loader}
              </button>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
