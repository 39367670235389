import Loader from "./Loader";
export default function PageLoader() {
  return (
    <div className="d-flex justify-content-center align-items-center w-100"
    style={{
      height: "70vh",
    }}
    >
      <Loader className="spinner-border-lg" />
    </div>
  );
}
