import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import Scan from "./source/Scan";
import { server, config } from "./env";
import axios from "axios";
import PageLoader from "./components/PageLoader";
import "./App.css";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const checkLogin = async () => {
    await axios
      .get(server + "/v3/pairingapp/auth/access", config)
      .then((res) => {
        setIsLogin(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return isLoaded ? (
    isLogin ? (
      <Routes>
        <Route path="*" element={<Scan />} />
      </Routes>
    ) : (
      <Routes>
        <Route path="*" element={<Login />} />
      </Routes>
    )
  ) : (
    <PageLoader />
  );
}

export default App;
