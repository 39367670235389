const Cookies = require("js-cookie");
const location = window.location.href;

module.exports = {
  server: location.includes("localhost")
    ? "http://localhost:7400"
    : "https://v3-api.wilyersignage.com",

  config: {
    headers: {
      authorization: "Bearer " + Cookies.get("pairingApp"),
    },
  },
};
