import React, { useState } from "react";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

const Scan = () => {
  const [loader, setLoader] = useState("");

  const pairScreen = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const data = {
      pairingCode: e.target.pairingCode.value,
      name: e.target.name.value,
      isDemo: false,
    };
    await axios
      .post(`${server}/v3/pairingapp/screen/create`, data, config)
      .then((res) => {
        setLoader("");
        toast.success("Pairing Successful");
      })
      .catch((err) => {
        setLoader("");
        toast.error(err.response.data.message);
      });

    setTimeout(() => {
      e.target.reset();
    }, 3000);
  };

  return (
    <div style={{ backgroundColor: "#D6EFED" }}>
      <div className="d-flex justify-content-end pt-3 pe-3">
        <button
          className="btn btn-danger"
          onClick={() => {
            Cookies.remove("pairingApp");
            window.location.href = "/";
          }}
        >
          Logout
        </button>
      </div>
      <div className="vh-100 d-flex flex-column justify-content-center align-items-center">
        <img
          src="/assets/wilyer.png"
          alt=""
          className="img-fluid mb-3 pairing_logo"
        />
        <div className="card lift shadow-sm pairing_card">
          <div className="card-body justify-content-center align-items-center h-100">
            <form onSubmit={pairScreen}>
              <div className="form-group mb-3">
                <label className="fw-bold fs-6">Screen Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Screen Name"
                  name="name"
                />
              </div>
              <div className="form-group mb-3">
                <label className="fw-bold fs-6">Pairing Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter pairing code"
                  name="pairingCode"
                />
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" type="submit">
                  Pair Screen {loader}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Scan;
